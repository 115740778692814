<template>
  <el-dialog
    title="Select Date"
    width="30%"
    :visible="isVisible"
    @close="handleClose"
  >
    <div>
      <div class="row">
        <div class="col-12">
          
          <label class="">This will mark all messages before the provided date and time as read</label>
          <!-- :value-format="'yyyy-MM-dd'" -->
          <el-date-picker
            v-model="date"
            :format="'dd MMMM yyyy hh:mm A'"
            type="datetime"
            placeholder="Pick a day"
            class="w-100"
            style="height: 40px !important"
          >
          </el-date-picker>
        </div>
        <div class="col-12 mt-3">
          <el-button
            style="
              background-color: var(--el-app-primary);
              height: 2.6rem;
            "
            class="z-depth-0 addbtn w-100"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            type="primary"
            :disabled="!date"
            @click="onContinue"
            >Continue</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      date: null,
    };
  },

  props: {
    isVisible: {
      required: true,
      type: Boolean,
    },
  },

  methods: {
    handleClose() {
      this.date = null;
      this.$emit("on-close");
    },
    async onContinue() {
      if (!this.date) return;
      this.$emit("mark-as-read", this.date);
      this.handleClose()
    },
  },
};
</script>

<style>
.el-date-editor .el-range-separator {
  width: 10%;
}
</style>
