<template>
  <div>
    <article>
      <section>
        <div class="container">
          <div class="">
            <el-dialog
              title="Contact Us Message"
              :visible="isVisible"
              :before-close="closeDialog"
              width="45%"
              @open="fetchOneContactUsMessage"
            >
              <div v-loading="loading" class="">
                <div class="row">
                  <div class="col-md-12">
                    <table class="table-borderless ms-2">
                      <tbody>
                        <tr>
                          <td class="bold-fw" style="width:20%">Sent At:</td>
                          <td class="pl-2">
                            {{ createdAt || "N/A" }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bold-fw" style="width:20%">Full name:</td>
                          <td class="pl-2">
                            {{ fullName || "N/A" }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bold-fw">Email address:</td>
                          <td class="pl-2">
                            {{ email || "N/A" }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bold-fw">Phone Number:</td>
                          <td class="pl-2">
                            {{ phoneNumber || "N/A" }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bold-fw">Message:</td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ message }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-12">
                    <el-button
                      style="
                        background-color: var(--el-app-primary);
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        float: right;
                      "
                      class="z-depth-0 mt-3"
                      v-ripple="'rgba(255, 255, 255, 0.35)'"
                      @click="toggleReadStatus"
                      :style="isReadStatus ? 'background-color: white; color: var(--el-app-primary)' : 'background-color: var(--el-app-primary); color: white'"
                    >
                      <span v-if="isReadStatus">Mark as Unread</span>
                      <span v-else>Mark as Read</span>
                    </el-button>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    isRead: {
      type: Boolean,
      required: false,
      default: false,
    },
    messageId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
      createdAt: "",
      isReadStatus: false,
    };
  },

  methods: {

    formatDate(dateIso) {
      if(!dateIso) return "N/A";
      return format(parseISO(dateIso), "do MMM yyyy hh:mma");
    },

    closeDialog() {
      this.$emit("re-fresh");
      this.$emit("close-dialog");
    },

    async fetchOneContactUsMessage() {
      try {
        
        this.isReadStatus = this.isRead;
        
        this.loading = true;
        let response = await this.$http.get(
          `website/contact-us/fetch-one/${this.messageId}`
        );

        if (
          response.data.success &&
          response.data.message ==
            "Website contact us message fetched successfully"
        ) {
          const message = response.data.data;
          this.fullName = message.full_name;
          this.phoneNumber = message.phone_number;
          this.email = message.email;
          this.message = message.message;
          this.createdAt = this.formatDate(message.created_at);
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.message == "Network Error") {
          this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
          return this.$emit("re-fresh");
        }
        this.showFailedMessage(
          "Unable to get message",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async toggleReadStatus() {
      try {
        this.loading = true;
        let response = await this.$http.patch(
          `website/contact-us/toggle-read-status/${this.messageId}`
        );

        if (
          response.data.success &&
          response.data.message ==
            "Website contact us message read status updated successfully"
        ) {
          this.isReadStatus = response.data.is_read
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        this.loading = true;
        if (error.message == "Network Error") {
          this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again"
          );
        }
        this.showFailedMessage(
          "Unable to change read status",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.bold-fw {
  font-weight: 500;
}
</style>
