<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Contact us messages']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Contact Us Messages </strong><br />
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="d-flex">
              <input
                type="text"
                v-model.trim="nameSearch"
                @input="onSearch"
                placeholder="Search by name"
                class="search_by_input border-color-fix"
                spellcheck="off"
              />

              <div style="margin-left: 20px">
                <el-select
                  v-model="selectedFilter"
                  placeholder="Select"
                  class="d-block"
                  style="height: 40px !important"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <el-dropdown
                trigger="click"
                @command="handleCommand"
                class="ml-3"
              >
                <el-button type="primary" class="button-option">
                  More Options<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="export-to-excel">
                    <span>Export to Excel</span>
                  </el-dropdown-item>

                  <el-dropdown-item command="bulk-mark-as-read">
                    <span>Bulk Mark as Read</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <hr />

          <div v-loading="loading" class="table-responsive">
            <data-tables-server
              :data="messages"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [10, 25, 50] }"
              type="expand"
              :total="totalMessages"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              @query-change="onTableChange"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getContactUsMessages"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Messages Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Messages</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Date"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="170px"
              >
                <template slot-scope="scope">
                  <span>{{ formatDate(scope.row.created_at) }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Name"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.full_name }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Email"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="180px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.email || "N/A" }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Phone Number"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="150px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.phone_number }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Message"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.message }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                label-class-name="text-left"
                width="80px"
              >
                <template slot-scope="scope">
                  <el-row
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <el-button
                      type="success"
                      class="mr-2 ml-2"
                      @click="
                        showMessageDetailsDialog(
                          scope.row.website_contact_us_message_id,
                          scope.row.is_read
                        )
                      "
                      :style="{
                        backgroundColor: scope.row.is_read
                          ? '#164B70'
                          : '#1E88E5',
                        borderColor: scope.row.is_read ? '#164B70' : '#1E88E5',
                      }"
                      icon="el-icon-view"
                      circle
                    >
                    </el-button>
                  </el-row>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </section>

      <ContactUsMessageDetailsDialog
        :is-visible="openMessageDetailsDialog"
        :messageId="messageId"
        :is-read="isRead"
        @re-fresh="getContactUsMessages"
        @close-dialog="closeMessageDetailsDialog"
      />

      <ChooseDateDialog
        :is-visible="chooseDateDialog"
        @on-close="chooseDateDialog = false"
        @mark-as-read="markAsRead"
      />
    </article>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import debounce from "debounce";
import ContactUsMessageDetailsDialog from "../components/dialogs/contact-us-message-details-dialog.vue";
import ChooseDateDialog from "@/components/dialogs/choose-contact-us-messages-date-dialog.vue";
import { format, parseISO } from "date-fns";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
    ContactUsMessageDetailsDialog,
    ChooseDateDialog,
  },

  data() {
    return {
      loading: false,
      loadingError: false,
      chooseDateDialog: false,
      messages: [],
      nameSearch: "",

      totalMessages: 0,
      currentPage: 1,
      pageSize: 10,

      tableProps: {
        border: true,
        stripe: true,
      },
      openMessageDetailsDialog: false,
      messageId: "",
      isRead: false,
      selectedFilter: "UNREAD",
      filterOptions: [
        {
          label: "Show All",
          value: "",
        },
        {
          label: "Only Unread",
          value: "UNREAD",
        },
        {
          label: "Only Read",
          value: "READ",
        },
      ],
    };
  },
  mounted() {
    this.getContactUsMessages();
  },

  watch: {
    selectedFilter() {
      this.onSearch();
    },
  },

  methods: {
    onSearch: debounce(function () {
      this.getContactUsMessages();
    }, 500),

    formatDate(dateIso) {
      return format(parseISO(dateIso), "do MMM yyyy hh:mma");
    },

    async onTableChange(queryInfo) {
      if (queryInfo) {
        if (
          queryInfo.type == "page" ||
          queryInfo.type == "size" /* ||
            queryInfo.type == "filter" */
        ) {
          this.currentPage = queryInfo.page;
          this.pageSize = queryInfo.pageSize;
          this.getContactUsMessages();
        }
      }
    },

    async getContactUsMessages() {
      try {
        //Show Loading state
        this.loading = true;
        this.loadingError = false;

        let response = await this.$http.get(
          `website/contact-us/fetch-many?page=${this.currentPage}&pageSize=${this.pageSize}&nameSearch=${this.nameSearch}&showOnly=${this.selectedFilter}`
        );
        if (
          response.data.success &&
          response.data.message ==
            "Website contact us messages fetched successfully"
        ) {
          this.messages = response.data.messages;
          this.totalMessages = response.data.total_messages;
        } else {
          //Report the Error to an error reporting service
          this.$rollbar.warning("ADMIN FRONT END: Unexpected API response", {
            response: response.data,
            request: response,
          });

          //Throw this as an error
          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        //Handle Network Errors
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to Load messages",
          "An unexpected Error Occurred. Please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async exportToExcel() {
      try {
        this.loading = true;
        this.loadingError = false;

        let response = await this.$http.get(
          `website/contact-us/fetch-many?page=${this.currentPage}&pageSize=${this.pageSize}&nameSearch=${this.nameSearch}&showOnly=${this.selectedFilter}&exportToExcel=true`
        );
        if (
          response.data.success &&
          response.data.message == "Website Messages exported successfully"
        ) {
          const link = document.createElement("a");
          link.href = response.data.link;
          link.click();
          return;
        } else {
          //Report the Error to an error reporting service
          this.$rollbar.warning("ADMIN FRONT END: Unexpected API response", {
            response: response.data,
            request: response,
          });

          //Throw this as an error
          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        //Handle Network Errors
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to Load messages",
          "An unexpected Error Occurred. Please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    async markAsRead(date) {
      try {
        this.loading = true;
        let response = await this.$http.patch(
          `website/contact-us/mark-many-as-read`,
          {
            date,
          }
        );
        if (
          response.data.success &&
          response.data.message == "Messages marked as read successfully"
        ) {
          this.getContactUsMessages()
          return this.showSuccessMessage("Success", "Messages marked successfully");
        } else {
          //Report the Error to an error reporting service
          this.$rollbar.warning("ADMIN FRONT END: Unexpected API response", {
            response: response.data,
            request: response,
          });

          //Throw this as an error
          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        //Handle Network Errors
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }

        this.loadingError = true;
        this.showFailedMessage(
          "Unable to Load messages",
          "An unexpected Error Occurred. Please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    showMessageDetailsDialog(messageId, is_read) {
      this.openMessageDetailsDialog = true;
      this.messageId = messageId;
      this.isRead = is_read;
    },

    closeMessageDetailsDialog() {
      this.openMessageDetailsDialog = false;
    },

    handleCommand(command) {
      if (command === "export-to-excel") {
        this.exportToExcel();
      }
      if (command === "bulk-mark-as-read") {
        this.chooseDateDialog = true;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }

  .addbtn {
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .search_add_section {
    display: block;
  }

  .addbtn {
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.course_request_form .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.course_request_form .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.course_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .course_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .course_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .course_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .course_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
